<template>
<div class='block galleryBlock'>              
  <v-container>
      <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">

      <h2 class='text-center'>{{ $t('pictures.title')}}</h2>

      <v-dialog v-model="dialog" max-width="945" max-height='600' @keydown.right="navigate('right', images.length-1)" @keydown.left="navigate('left', images.length-1)">
        <v-carousel hide-delimiters v-model="imageIndex">
        <v-carousel-item
        v-for="image in images"
        :key="image.id"
        :src="image.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
        >
        </v-carousel-item>
        </v-carousel>
      </v-dialog>
                  
      <v-row>
        <v-col
          v-for="image in images"
          :key="image.id"
          class="d-flex child-flex"
          cols="6"
          sm="4"
        >
          <v-card flat tile class='d-flex'>
              <v-img
              :src="image.src"
              aspect-ratio="1"
              class="grey lighten-2"
              @click.stop="openCurrentImage(image.id)"
              >
              <template v-slot:placeholder>
                  <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                  >
                  <v-progress-circular
                      indeterminate
                      color="amber"
                  ></v-progress-circular>
                  </v-row>
              </template>
              </v-img>
          </v-card>
        </v-col>
      </v-row>    
  </v-container>
</div>
</template>

<script>
export default {
    name: 'Galerie',
    data() {
        return {
            images: [
                {
                    id:0,
                    src: require('../assets/photos/arnelleCloche.jpg')
                },
                {
                    id:1,
                    src: require('../assets/photos/mariage.jpg')
                },
                {
                    id:2,
                    src: require('../assets/photos/BuffetCafe.jpg')
                },
                {
                    id:3,
                    src: require('../assets/photos/couronnetabledhonneur.jpg')
                },
                {
                    id:4,
                    src: require('../assets/photos/decotablePattyn.jpg')
                },
                {
                    id:5,
                    src: require('../assets/photos/Tabledhonneur.jpg')
                },
                {
                    id:6,
                    src: require('../assets/photos/tente_exterieur.jpg')
                },
                {
                    id:7,
                    src: require('../assets/photos/crudites.jpg')
                },
                {
                    id:8,
                    src: require('../assets/photos/arnelle_table.jpg')
                },
                {
                    id:9,
                    src: require('../assets/photos/sushi.jpg')
                },
                {
                    id:10,
                    src: require('../assets/photos/toast.jpg')
                },
                {
                    id:11,
                    src: require('../assets/photos/fleur.jpg')
                }
            ],
            dialog: false,
            imageIndex:null
        }
    },
    methods: {
      openCurrentImage(index) {
        this.imageIndex = index;
        this.dialog = true;
      }
    }
    
}
</script>

<style scoped>
  .galerie {
      /* margin-top: 100px; */
      font-family: 'Raleway', sans-serif;
  }

.block {
  padding: 60px 0;
  border-bottom: 1px solid darken(white, 10%);
}

  h2 {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;
  }
</style>